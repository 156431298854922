import i18next from 'i18next';                        //i18next translation library
import { initReactI18next } from 'react-i18next';
import translationEn from './locale/en.json'
import translationAr from './locale/ar.json'
// import languageDetector from 'i18next-browser-languagedetector'
const resources={
  en:{
    translation:translationEn
  },
  ar:{
    translation:translationAr
  }  
}

i18next
// .use(languageDetector)
.use(initReactI18next)
.init({
  resources,
  lng:"en",
  interpolation:{
    escapeValue:false
  },
  react:{
    useSuspense:true
  }
})

export default i18next