import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import styles from "./styles.module.css";
import Header from "../Header/index";
import Intro from "./IntroSection/index";
import Egroscout from "./Egroscout/index";
import Egrocare from "./Egrocare/index";
import Egroscan from "./Egroscan/index";
import Advantages from "./Advantages/index";
import Footer from "./Footer/index";
import Packages from "./Packages/index";
import t1 from "../../assets/Home/HeroSection/t1.png";
import t2 from "../../assets/Home/HeroSection/t2.png";
import t3 from "../../assets/Home/HeroSection/t3.png";
import t4 from "../../assets/Home/HeroSection/t4.png";
import t5 from "../../assets/Home/HeroSection/t5.png";
import bgVideo from "../../assets/Home/HeroSection/bg-video.mp4";
import arrow from "../../assets/Home/HeroSection/arrow.png";
import logo from "../../assets/Home/HeroSection/logo.png";
import { useTranslation, Trans } from "react-i18next";

const Home = () => {
  const [t, i18n] = useTranslation(); //translation hook
  const isArabic = i18n.language === "ar";

  return (
    <>
      <div className={styles.heroSectionContainer}>
        <video autoPlay muted loop className={styles.backgroundVideo}>
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={styles.overlay}></div>
        <div className={styles.triangleWrapper}>
          <img
            src={t1}
            className={`${styles.triangle} ${styles.triangle1}`}
            alt="Triangle 1"
          />
          <img
            src={t2}
            className={`${styles.triangle} ${styles.triangle2}`}
            alt="Triangle 2"
          />
          <img
            src={t3}
            className={`${styles.triangle} ${styles.triangle3}`}
            alt="Triangle 3"
          />
          <img
            src={t4}
            className={`${styles.triangle} ${styles.triangle4}`}
            alt="Triangle 4"
          />
          <img
            src={t5}
            className={`${styles.triangle} ${styles.triangle5}`}
            alt="Triangle 5"
          />
        </div>
        <Header />
        <main className={styles.heroSection}>
          <h1 className={styles.heroSectionHeading}>
            <Trans
              i18nKey="sentence"
              components={{
                1: <span className={styles.highlight} />,
                2: <span className={styles.highlight} />,
              }}
            />
          </h1>
          <h2 className={styles.heroSectionText}>
            {t("Cloud-Based Convenience.")}
          </h2>
          <Link to="introSection" smooth={true} duration={500}>
            <button className={styles.heroSectionButton}>
              <span className={styles.heroSectionButtonText}>
                {t("what we serve?")}
              </span>
              <img src={arrow} alt="arrow" className={styles.arrowIcon} />
            </button>
          </Link>
        </main>
        <div className={`${styles.logoContainer} ${isArabic ? styles.ar : ""}`}>
          <img src={logo} className={styles.logoIcon} alt="" />
        </div>
      </div>
      <Intro />
      <Egroscout />
      <Egrocare />
      <Egroscan />
      <Advantages />
      {/* <Packages /> */}
      <Footer />
    </>
  );
};
export default Home;