import logo from './logo.svg';
import React, { useEffect } from "react";
import './App.css';
import Home from "./components/Home/index";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation(); //translation hook

  useEffect(() => {
    const currentLang = i18n.language;
    if (currentLang === "ar") {
      document.body.classList.add("rtl");
      document.body.style.direction = "rtl";
    } else {
      document.body.classList.remove("rtl");
      document.body.style.direction = "ltr";
    }
  }, [i18n.language]);

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
