import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import egroscanIcon from "../../../assets/Home/IntroSection/egroscan-card-icon.png";
import arrowEn from "../../../assets/Home/Egroscout/arrow-en.png";
import arrowAr from "../../../assets/Home/Egroscout/arrow-ar.png";
import logo from "../../../assets/Home/Egroscan/logo.png";
import video from "../../../assets/Home/Egroscan/video.mp4";
import { useTranslation, Trans } from "react-i18next";

const Egroscout = () => {
  const [t, i18n] = useTranslation(); //translation hook
  const arrow = i18n.language === "ar" ? arrowAr : arrowEn;

  return (
    <div id="egroscan" className={styles.container}>
      <section className={styles.leftSection}>
        <div className={styles.iconTitleWrapper}>
          <img src={egroscanIcon} alt="" className={styles.icon} />
          <h2 className={styles.title}>{t("Egro Scan")}</h2>
        </div>
        <h3 className={styles.description}>
          {t("Plants Diseases Detection System.")}
        </h3>
        <p className={styles.text}>
          {t("Egro Scan is designed specifically for farmers and plant enthusiasts.")}
        </p>
        <p className={styles.text}>
          {t("They can easily manage their agricultural needs and get the most out of their plants through disease detection, expert guidance, and personalized care tips.")}
        </p>
        <div className={styles.buttonsContainer}>
          <a
            href="https://play.google.com/store/apps/details?id=com.egrobots.shagarah"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security best practice to prevent security risks
          >
            <button className={styles.downloadButton}>
              <span>{t("download the app")}</span>
            </button>
          </a>
          {/* <button className={styles.knowMoreButton}>
            <span>{t("know more")}</span>
            <img src={arrow} alt="arrow" className={styles.arrowIcon} />
          </button> */}
        </div>
      </section>
      <section className={styles.rightSection}>
        <img src={logo} className={styles.logoIcon} alt="" />
        <video autoPlay muted loop className={styles.video}>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={styles.overlay}></div>
      </section>
    </div>
  );
};

export default Egroscout;