import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import egroscoutIcon from "../../../assets/Home/IntroSection/egroscout-card-icon.png";
import arrowEn from "../../../assets/Home/Egroscout/arrow-en.png";
import arrowAr from "../../../assets/Home/Egroscout/arrow-ar.png";
import logo from "../../../assets/Home/Egroscout/logo.png";
import video from "../../../assets/Home/Egroscout/video.mp4";
import { useTranslation, Trans } from "react-i18next";

const Egroscout = () => {
  const [t, i18n] = useTranslation(); //translation hook
  const arrow = i18n.language === "ar" ? arrowAr : arrowEn;

  return (
    <div id="egroscout" className={styles.container}>
      <section className={styles.leftSection}>
        <div className={styles.iconTitleWrapper}>
          <img src={egroscoutIcon} alt="" className={styles.icon} />
          <h2 className={styles.title}>{t("Egro Scout")}</h2>
        </div>
        <h3 className={styles.description}>
          {t("Comprehensive Farm Analysis using Autonomous Robots.")}
        </h3>
        <p className={styles.text}>
          {t(
            "Experience the future of farming with our Robot Scouting service. At Egrobots, we believe in harnessing cutting-edge technology to transform agriculture. Our autonomous robots are revolutionizing the way farms are managed."
          )}
        </p>
        <p className={styles.text}>
          {t(
            "With precise data collection and AI-driven analysis, we're helping farmers make informed decisions, boost productivity, and ensure the health of every plant."
          )}
        </p>
        {/* <div className={styles.buttonContainer}>
          <button className={styles.button}>
            <span>{t("know more")}</span>
            <img src={arrow} alt="arrow" className={styles.arrowIcon} />
          </button>
        </div> */}
      </section>
      <section className={styles.rightSection}>
        <img src={logo} className={styles.logoIcon} alt="" />
        <video autoPlay muted loop className={styles.video}>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={styles.overlay}></div>
      </section>
    </div>
  );
};

export default Egroscout;
