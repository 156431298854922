import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import egroscoutBg from "../../../assets/Home/IntroSection/egroscout-card-bg.png";
import egroscoutIcon from "../../../assets/Home/IntroSection/egroscout-card-icon.png";
import egrocareBg from "../../../assets/Home/IntroSection/egrocare-card-bg.png";
import egrocareIcon from "../../../assets/Home/IntroSection/egrocare-card-icon.png";
import egroscanBg from "../../../assets/Home/IntroSection/egroscan-card-bg.png";
import egroscanIcon from "../../../assets/Home/IntroSection/egroscan-card-icon.png";
import arrow from "../../../assets/Home/IntroSection/arrow.png";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-scroll";

const IntroSection = () => {
  const [t, i18n] = useTranslation(); //translation hook
  const iconClass = i18n.dir() === "rtl" ? styles.iconRTL : styles.iconLTR;
  const arrowClass = i18n.dir() === "rtl" ? styles.arrowRTL : styles.arrowLTR;

  return (
    <section className={styles.introSection}>
      <h2 id="introSection" className={styles.introSectionHeading}>
        {t("How Can We")}{" "}
        <span>
          {t("Serve Your")}
          <br />
          {t("Farm")}
        </span>{" "}
        <span>{t("?")}</span>
      </h2>
      <p className={styles.introSectionText}>
        {t("Egrobots, where cutting-edge AI and robotic solutions meet agricultural expertise. We specialize in empowering farmers and farm owners with innovative technologies that revolutionize traditional farming practices. From personalized advice to automated solutions, we pave the way for sustainable and efficient farming practices. Discover how our tailored services can elevate your agricultural operations to new heights. We provide you with all these advantages through 3 services :")}
      </p>
      <div className={styles.introSectionCards}>
        <div className={styles.cardContainer}>
          <div
            className={`${styles.egroscoutCardWrapper} ${styles.cardWrapper}`}
          >
            <img src={egroscoutBg} alt="" className={styles.cardWrapperBgImg} />
            <img
              src={egroscoutIcon}
              alt=""
              className={`${styles.cardWrapperIconImg} ${iconClass}`}
            />
          </div>
          <Link
            to="egroscout"
            smooth={true}
            duration={500}
            className={styles.card}
          >
            <img
              src={arrow}
              alt=""
              className={`${styles.arrow} ${arrowClass}`}
            />
            <span>{t("Egro Scout")}</span>
          </Link>
        </div>
        <div className={styles.cardContainer}>
          <div
            className={`${styles.egrocareCardWrapper} ${styles.cardWrapper}`}
          >
            <img src={egrocareBg} alt="" className={styles.cardWrapperBgImg} />
            <img
              src={egrocareIcon}
              alt=""
              className={`${styles.cardWrapperIconImg} ${iconClass}`}
            />
          </div>
          <Link
            to="egrocare"
            smooth={true}
            duration={500}
            className={styles.card}
          >
            <img
              src={arrow}
              alt=""
              className={`${styles.arrow} ${arrowClass}`}
            />
            <span>{t("Egro Care Pro")}</span>
          </Link>
        </div>
        <div className={styles.cardContainer}>
          <div
            className={`${styles.egroscanCardWrapper} ${styles.cardWrapper}`}
          >
            <img src={egroscanBg} alt="" className={styles.cardWrapperBgImg} />
            <img
              src={egroscanIcon}
              alt=""
              className={`${styles.cardWrapperIconImg} ${iconClass}`}
            />
          </div>
          <Link
            to="egroscan"
            smooth={true}
            duration={500}
            className={styles.card}
          >
            <img
              src={arrow}
              alt=""
              className={`${styles.arrow} ${arrowClass}`}
            />
            <span>{t("Egro Scan")}</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
