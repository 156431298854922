import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import styles from "./styles.module.css";
import videoImg from "../../../assets/Home/Advantages/vid-img.png";
import logoLeft from "../../../assets/Home/Advantages/logo-left.png";
import logoRight from "../../../assets/Home/Advantages/logo-right.png";
import playBtn from "../../../assets/Home/Advantages/play-btn.png";
import g1 from "../../../assets/Home/Advantages/g1.png";
import g2 from "../../../assets/Home/Advantages/g2.png";
import g3 from "../../../assets/Home/Advantages/g3.png";
import g4 from "../../../assets/Home/Advantages/g4.png";
import t1 from "../../../assets/Home/Advantages/t1.png";
import t2 from "../../../assets/Home/Advantages/t2.png";
import t3 from "../../../assets/Home/Advantages/t3.png";
import t4 from "../../../assets/Home/Advantages/t4.png";
import { useTranslation, Trans } from "react-i18next";

const Advantages = () => {
  const [t, i18n] = useTranslation(); //translation hook
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
      setIsPlaying(true);
    };


  return (
    <>
      <div className={styles.advantagesContainer}>
        <div className={styles.videoCard}>
          <img src={logoLeft} alt="" className={styles.leftLogo} />
          <img src={logoRight} alt="" className={styles.rightLogo} />
          <div className={styles.videoWrapper}>
            {!isPlaying && (
              <img src={videoImg} alt="" className={styles.videoImage} />
            )}
            {isPlaying && (
              <>
                {i18n.language === "en" && (
                  <iframe
                    className={styles.video}
                    src="https://www.youtube.com/embed/xBYwkjQx1MQ?autoplay=1"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                )}
                {i18n.language === "ar" && (
                  <iframe
                    className={styles.video}
                    src="https://www.youtube.com/embed/l86olJcuHgA?autoplay=1"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                )}
              </>
            )}
            {!isPlaying && (
              <div
                className={styles.playBtnContainer}
                onClick={handlePlayClick}
              >
                <div className={styles.whiteGlowAnimation}></div>
                <div className={styles.playBtnInnerContainer}>
                  <img src={playBtn} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>

        <h2 className={styles.title}>
          {t("Grower ")}
          <span>{t("Advantages ")}</span>
          <span>.</span>
        </h2>

        <div className={styles.insightsContainer}>
          <div className={styles.insightCard}>
            <img src={g1} alt="Icon 1" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>
              {t("Unbiased Distinctive & Actionable Insights")}
            </h3>
            <p className={styles.insightText}>
              {t("No more guesswork or worrying about whether you really need the inputs you're being sold. Clear, undisputed insights brought by a trusted advisor let you act with confidence.")}
            </p>
          </div>

          <div className={styles.insightCard}>
            <img src={g2} alt="Icon 2" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>
              {t("More Efficient Use Of Resources")}
            </h3>
            <p className={styles.insightText}>
              {t("More targeted insights mean more targeted prescriptions. Buy what you use and use what you buy — more efficient, more sustainable intervention.")}
            </p>
          </div>

          <div className={styles.insightCard}>
            <img src={g3} alt="Icon 3" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>
              {t("Healthier Crops & Higher Yield Potential")}
            </h3>
            <p className={styles.insightText}>
              {t("Earlier, more accurate and more precise intervention made possible by unmatched insights and AI knowledge means better plant health and yield.")}
            </p>
          </div>

          <div className={styles.insightCard}>
            <img src={g4} alt="Icon 4" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>{t("Better Year Round Planning")}</h3>
            <p className={styles.insightText}>
              {t("The story of your fields is always at your fingertips. Now, you have the information you need for analysis and predictive planning.")}
            </p>
          </div>
        </div>

        <h2 className={styles.title}>
          {t("Trusted Advisor")}
          <span>{t(" Advantages ")}</span>
          <span>.</span>
        </h2>

        <div className={styles.insightsContainer}>
          <div className={styles.insightCard}>
            <img src={t1} alt="Icon 1" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>
              {t("Brand Experience & Differential")}
            </h3>
            <p className={styles.insightText}>
              {t("RoboScout® & Shagara™ empower you with the knowledge and insights about your growers and their crops. Simply put, you set the pace and the competition will never catch up.")}
            </p>
          </div>

          <div className={styles.insightCard}>
            <img src={t2} alt="Icon 2" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>
              {t("Unbiased & Trusted Engagement")}
            </h3>
            <p className={styles.insightText}>
              {t("You’re not just another sales call. You’re the trusted advisor he’s waiting to hear from, and reaches out to, because RoboScout® gives you something worth talking about.")}
            </p>
          </div>

          <div className={styles.insightCard}>
            <img src={t3} alt="Icon 3" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>
              {t("Increase Retailer & Grower Profit")}
            </h3>
            <p className={styles.insightText}>
              {t("One size never fits all. Now you can bring more precise inputs that are specifically tailored to the issue at hand. The right solution means more profit and opportunity.")}
            </p>
          </div>

          <div className={styles.insightCard}>
            <img src={t4} alt="Icon 4" className={styles.cardIcon} />
            <h3 className={styles.insightTitle}>{t("Bring Scale To Your Team")}</h3>
            <p className={styles.insightText}>
              {t("Cover more ground with your sales agronomy team. Scout from anywhere, anytime. Know where to go — prioritize grower fields requiring the most urgent attention.")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Advantages;
